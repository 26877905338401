@import "common.css";

.unit-view {
	margin: 112px 0 0;

	@media all and (max-width:992px) {
		#more {
			display: none;
		}
	}

	.page-header {
		border-bottom: 2px solid var(--Primarycolor);

		h1 {
			color: var(--Primarycolor);

			small {
				font-style: italic;
				color: var(--Primarycolor);
			}
		}
	}

	.facility-descp {
		@media (max-width:992px) {
			border: 1px solid rgba(143, 131, 113, 1);
			display: flex;
			flex-direction: column-reverse;
			position: relative;
		}

		.facility-cont {
			@media (max-width:992px) {
				padding: 0 15px 15px;
			}

			h2 {
				@media (max-width:992px) {
					margin-top: 20px;
					margin-bottom: 15px;
					font-size: 16px;
				}
			}
		}

		button {
			display: none;
			border: none;
			background-color: transparent;
			font-size: 18px;

			@media (max-width:992px) {
				position: absolute;
				bottom: 0;
				display: block;
				left: 50%;
				transform: translate(-50%, 0);
			}

			i {
				font-style: normal;
			}
		}
	}

	.asd__inner-wrapper {
		.asd__month-name {
			color: var(--Primarycolor);
		}

		.asd__change-month-button>button {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}


	#accordion {
		.collapse {

			@media (min-width:768px) {
				display: block;
			}
		}

		.card {
			background-color: transparent;
			margin: 0 0 40px;

			@media (max-width:767px) {
				margin: 0 0 10px;
			}

			.card-header {
				margin: 0;

				>h2 {
					margin: 0 0 10px;

					@media (max-width:767px) {
						font-size: 16px;
						font-weight: 600;
						line-height: 40px;
					}
				}

				.btn-link {
					background-color: transparent;
					color: var(--Primarycolor);
					padding: 0;
					font-weight: 500;

					@media (min-width:768px) {
						pointer-events: none;
					}

					span {
						color: inherit;
						font-size: inherit;
					}

					&:after {
						content: "\f107";
						font-family: var(--fa-style-family, "Font Awesome 6 Free");
						font-weight: var(--fa-style, 900);
						background: transparent;
						top: 0;

						@media (min-width:768px) {
							display: none;
						}
					}
				}
			}

			.card-body {
				padding: 0;
				border: none;
			}
		}

	}

	.intro {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.properties-lable {
			color: var(--Primarycolor);
		}
	}

	.properties {
		&.ausstattung {
			.prop {
				margin-bottom: 10px;
				display: inline-flex;
				-webkit-box-align: center;
				align-items: center;
				width: 50%;

				@media (max-width:767px) {
					font-size: 12px;
					line-height: 18px;
				}

				i {
					position: relative;
					top: 0;
					margin-right: 30px;
					font-size: 28px;
					width: 35px;

					@media (max-width:767px) {
						font-size: 18px;
						width: 24px;
						margin-right: 16px;
					}

					&.icon-kuhlschrank,
					&.icon-dusche,
					&.icon-toaster,
					&.icon-balcony,
					&.icon-gefrierfach,
					&.icon-kuche,
					&.icon-tv {
						@media (max-width:767px) {
							background-size: 16px !important;
						}
					}
				}
			}
		}
	}

	.unit-information-box-v1 {
		background-color: #f4f1ed;
		border: 1px solid var(--Primarycolor);
		padding: 15px;
		margin-bottom: 30px !important;

		@media(min-width:1200px) {
			margin-top: 126px;
		}

		.location {
			i {
				width: 16px;
				height: 16px;
				margin-right: 10px;
				color: var(--Primarycolor);
				filter: brightness(0) saturate(100%) invert(51%) sepia(24%) saturate(269%) hue-rotate(357deg) brightness(97%) contrast(85%);
			}
		}

		.ul-nopints {
			list-style: none;
			padding-left: 0;

			li {
				margin-bottom: 5px;

				i {
					margin-right: 10px;
					color: var(--Primarycolor);
					filter: brightness(0) saturate(100%) invert(51%) sepia(24%) saturate(269%) hue-rotate(357deg) brightness(97%) contrast(85%);
				}
			}
		}
	}

	.unit-banner {
		position: relative;
		/*cursor: pointer;*/
	}

	.d-flex {
		flex-wrap: wrap;
		display: flex;
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 992px) {
			width: 100% !important;
		}

		@media(min-width: 993px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 992px) {
			width: 100% !important;
		}

		@media(min-width: 993px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}



	.unit-banner-nav {
		font-size: 100px;
		position: absolute;
		top: 250px;
		line-height: 150px;
		width: 100%;
		display: none;

		.v-left-gallery-btn {
			position: absolute;
			left: 0;
		}

		.v-right-gallery-btn {
			position: absolute;
			right: 0;
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;

		/*a {
			pointer-events: none;
		}*/
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {
		pointer-events: none;
		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}

	p {
		color: var(--Primarycolor);

		@media (max-width:767px) {
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
		}
	}


	.tick-li {
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		list-style: none;
		padding: 0;

		&.li-pin {
			padding-top: 15px;

			li {
				padding-left: 25px;

				&:before {
					content: "";
					background-image: url(RESOURCE/img/icon-map-pin.svg);
					width: 16px;
					height: 16px;
					display: inline-block;
					position: absolute;
					top: 2px;
					left: 1px;
				}
			}
		}

		li {
			width: 50%;
			padding-left: 0;
			padding-right: 15px;
			min-height: 30px;
			list-style: none;
			position: relative;

			@media (max-width: 480px) {
				width: 100%;
			}
		}
	}

	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;
		padding-bottom: 30px;
		max-width: 1200px;

		h2 {
			margin-top: 40px;
			font-size: 22px;
			color: var(--Primarycolor);
			margin-bottom: 25px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding: 0;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 10px;
				margin: 5px 0;

				.day {
					width: 24px;
					height: 24px;
					display: inline-block;
					margin: 0 10px 0 0;
					border: 1px solid #e5e5e5;

					&.day-free {
						background: #fff;
					}

					&.day-full {
						background: var(--cal_not_available);
					}

					&.day-end {
						background: linear-gradient(90deg, #fff 80%, var(--cal_not_available) 20%) !important;
					}

					&.day-start {
						background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 20%) !important;
					}
				}
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				color: var(--Primarycolor);
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
					color: var(--Primarycolor);
					filter: brightness(0) saturate(100%) invert(51%) sepia(24%) saturate(269%) hue-rotate(357deg) brightness(97%) contrast(85%);

					@media (max-width:767px) {
						font-size: 16px;
					}
				}

				>div {
					text-align: center;
					margin: 10px 0;
					min-height: 56px;
					padding: 0 10px;

					@media (max-width:767px) {
						font-size: 12px;
						min-height: inherit;
						line-height: 1.2;
						max-width: 62px;
					}
				}
			}

			.properties {
				/*margin-left: 15px;
				margin-right: 15px;*/
				color: var(--Primarycolor);
				display: flex;
				flex-wrap: wrap;

				@media (max-width:767px) {
					column-gap: inherit;
					column-count: 2;
				}

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.ratings {
				margin: 0 0 30px 0;
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.unit-right-sidebar {
			margin: 30px 0 0 0;
			background: #fff;

			#sidebar {
				background: #fff;
				width: 100%;
				top: 0;
				position: relative;
			}

			#sidebar.noaction.affix {
				position: fixed !important;
				width: 370px !important;
				top: 130px !important;
				z-index: 9;
			}



			.contact-form {
				border: 1px solid var(--Primarycolor);

				h2 {
					height: 40px;
					padding: 1px;
					background-color: var(--Primarycolor);
					color: #fff;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					margin: 0 0 20px 0;
				}

				.c-form-inner {
					padding: 5px 15px 15px 15px;

					label {
						text-align: left;
					}
				}

				.checkbox {
					a {
						color: var(--Primarycolor);
					}
				}

				.btn-go {
					background-color: var(--Primarycolor);
					border: 1px solid var(--Primarycolor);
					border-radius: 0;
				}
			}
		}


		.info-col {
			position: relative;
			right: 0;
			top: 0;
			padding: 0;
			width: 100%;
			margin: 0 0 30px 0;
			/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: var(--Primarycolor);
				text-align: center;

				h2 {
					margin-top: 10px;
					font-size: 18px;
					color: #fff;
				}
			}

			.info-col-main {
				border: 1px solid var(--Primarycolor);
				border-bottom: none;
				width: 100%;
			}

			.subprice {
				width: 100%;
				border: 1px solid var(--Primarycolor);
				border-top: none;
				border-bottom: 0;
			}

			.fav-section {
				margin-top: 0;
				border-bottom: 1px solid var(--Primarycolor) !important;
				border-top: 0px solid var(--Primarycolor);
				padding: 15px;
			}

		}

		.sticky {

			background-color: #fff;
			z-index: 1;

			@media (min-width:768px) {
				position: sticky;
				top: 130px;
			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 15px 15px;

				.search-section {
					display: block;

					.searchbox {
						width: 100%;
						margin: 5px 0;

						.search-popup-trigger {
							border-radius: 0;
						}
					}
				}
			}

			.subprice {

				padding: 1px 15px 15px 15px;


				.filters {}


				.book-btn {
					margin-top: 20px;
					margin-bottom: 10px;
					font-size: 15px;
					padding: 10px;
					border-radius: 0;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 300px;
			width: 100%;
			margin-bottom: 15px;
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 280px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}

		.page-header {
			margin: 0 0 10px;

			h1 {
				font-size: 18px;
			}
		}

		.intro {
			marign-bottom: 10px;
		}

		.main-section {

			.main-col {


				.description {
					margin: 0 0 15px;
				}
			}
		}
	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 0 0 30px 0;
		background-color: white;
		padding: 0;
		width: 100%;

		.unit-img {
			img {
				width: 100%;
				height: 300px;
				object-fit: cover;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}
		}

		.unit-cont-bl {
			background: #f4f1ed;
			padding: 15px;
			border: 1px solid #dadada;
			border-top: 0;

			.ratings {
				color: var(--Primarycolor);
				font-size: 16px;
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				min-height: 25px;

				.fa-star {
					color: #f1d803;
				}
			}

			.unit-title {
				color: var(--Primarycolor);
				margin: 15px 0 15px;
				text-align: left;
				width: 100%;
				font-size: 22px;
				min-height: 50px;
			}

			.unit-address {
				display: flex;
				align-items: center;
				color: var(--Primarycolor);
				margin: 0 0 15px 0;

				.fa {
					margin: 0 6px 0 0;
				}

			}

			.btn-block {
				background-color: #b5aca0;
				border: 1px solid #b5aca0;
				color: #fff;
				display: flex;
				padding: 5px 12px;
				margin: 0;
				text-decoration: none;
				font-size: 15px;
				align-items: center;
				justify-content: center;
				height: 40px;

				&:hover {
					background: var(--Primarycolor);
					border: 1px solid var(--Primarycolor);
				}

				i {
					margin: 0 0 0 5px;
				}
			}

			.search-result-properties {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				align-items: center;
				margin: 0 0 5px;

				.properties-list {
					margin: 0 0px 10px 0;
					color: var(--Primarycolor);
					display: flex;
					align-items: center;

					&:after {
						display: block;
						content: " ";
						width: 4px;
						height: 4px;
						background: var(--Primarycolor);
						border-radius: 100%;
						margin: 0 10px;
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}

			.property-bottom {
				display: flex;
				align-items: center;
				margin: 0 0 20px 0;
				flex-wrap: wrap;

				.properties-list {
					border: 1px solid #000;
					width: 27px;
					height: 27px;
					font-size: 14pt;
					line-height: 24px;
					margin-right: 5px;
					margin-bottom: 5px;
					border-radius: 6px;
					display: -webkit-box;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;
				}
			}
		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}

@media (max-width: 992px) {
	.unit-view {
		margin-top: 58px;

		.mob-reverse {
			display: flex;
			flex-direction: column-reverse;
		}

		.main-section {
			.unit-right-sidebar {
				#sidebar {
					position: static !important;
					width: 100%;
				}

				#sidebar.noaction.affix {
					position: static !important;
					width: 100% !important;
				}
			}

			.info-col {
				.subprice {
					.book-btn {
						width: 100%;
						margin: 10px 0;
					}
				}
			}
		}

		.info-col {
			.info-col-main {
				.search-section {
					display: block;

					.searchbox {
						margin: 5px 0;
					}
				}
			}
		}

		.unit-banner-nav {
			font-size: 70px;
			top: 150px;
			line-height: 70px;
			width: 100%;
			display: none;
		}
	}

	.unit-list-section {
		.single-unit {
			.unit-img {
				img {

					height: 250px;
				}
			}
		}
	}

}

@media all and (min-width: 768px) and (max-width: 991px) {
	.unit-view {
		.main-section {
			width: 750px;

			.unit-right-sidebar {
				#sidebar.noaction.affix {
					width: 220px !important;
				}
			}

			.info-col {
				.subprice {
					.book-btn {
						width: 100%;
						margin: 10px 0;
					}
				}
			}
		}

		.unit-banner-nav {
			top: 210px;
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.unit-view {
		.main-section {
			.unit-right-sidebar {
				#sidebar.noaction.affix {
					width: 30% !important;
				}
			}
		}
	}
}