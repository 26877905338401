  .unit-image-gallery-v1 {
  	margin-right: -5px;
  	margin-left: -5px;
  	margin-top: -5px;

  	.visible-print {
  		display: none !important;
  	}

  	.unitGallery {
  		a {
  			display: none;
  			padding: 5px;
  			height: 100px;

  			@media (max-width: 767px) {
  				padding: 5px;
  			}

  			img {
  				width: 100%;
  				height: 100%;
  				object-fit: cover;
  			}

  			&:nth-child(1) {
  				width: 100%;
  				float: right;
  				display: block;
  				height: 518px;
  				overflow: hidden;

  				@media (max-width: 767px) {
  					width: 100%;
  					height: 220px;
  				}
  			}

  			&:nth-child(2) {
  				clear: both;

  				@media (max-width: 767px) {
  					clear: none;
  					height: 140px;
  				}
  			}

  			&:nth-child(2),
  			&:nth-child(3),
  			&:nth-child(4),
  			&:nth-child(5),
  			&:nth-child(6) {
  				display: block;
  				width: 20%;
  				float: left;

  				@media (max-width: 767px) {
  					width: 25%;
  					height: 65px;
  				}
  			}

  			&:nth-child(5) {
  				@media (max-width: 767px) {
  					display: none;
  				}
  			}

  			&:nth-child(6) {
  				position: relative;

  				&:before {
  					content: "+";
  					position: absolute;
  					left: 50%;
  					transform: translate(-50%, -30%);
  					top: 50%;
  					bottom: 0;
  					text-align: center;
  					width: 100%;
  					color: #fff;
  					font-weight: 600;
  					font-size: 37px;
  					margin-top: -10px;
  					z-index: 1;
  					padding: 0 10px;
  				}

  				&:after {
  					content: "";
  					position: absolute;
  					left: 5px;
  					right: 5px;
  					top: 5px;
  					bottom: 5px;
  					background: rgba(217, 217, 217, 0.57);
  					z-index: 0;

  					@media (max-width: 767px) {
  						top: 5px;
  						bottom: 5px;
  					}
  				}

  			}


  		}
  	}

  }