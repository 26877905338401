@import "common.css";

.static-view {
	.inner-page-banner {
		width: 100%;
		height: 550px;
		background-size: cover !important;
		background-position: center !important;
	}

	.leisure-tips-banner {
		background: url(RESOURCE/img/sbg6.jpg);
	}

	.schloss-stolpe-banner {
		background: url(RESOURCE/img/sbg1.jpg);
	}

	.west-wing-banner {
		background: url(RESOURCE/img/wes-4.jpg);
	}

	.remise-banner {
		background: url(RESOURCE/img/rem-3.jpg);
	}

	.country-yard-banner {
		background: url(RESOURCE/img/land-1.jpg);
	}

	.contact-banner {
		background: url(RESOURCE/img/sbg4.jpg);
	}

	.impressum-banner {
		background: url(RESOURCE/img/sbg1.jpg);
	}

	.banner-slide {
		img {
			height: 550px;
			object-fit: cover;
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	.static-content {
		padding: 70px 0;

		h3 {
			margin: 0 0 30px;
			font-size: 40px;
			font-weight: 500;
			color: var(--Primarycolor);
			position: relative;
			width: 100%;

			&:after {
				width: 80px;
				height: 2px;
				display: block;
				content: " ";
				background: #8f8371;
				background: var(--Primarycolor);
				margin-top: 15px;
			}
		}

		p {
			margin-top: 0;
			color: var(--Primarycolor);
			margin-bottom: 20px;
			font-size: 16px;
			line-height: 24px;

			a {
				color: var(--Primarycolor);
				text-decoration: underline;
			}

			.btn-primary {
				color: #fff;
				text-decoration: none;
				background-color: #b5aca0;
				border: 1px solid #b5aca0;
				white-space: normal;

				&:hover {
					background: var(--Primarycolor);
					border: 1px solid var(--Primarycolor);
				}
			}
		}

		.unit-list-section {
			display: flex;
			flex-wrap: wrap;
		}

	}

	.grey-cont-bl {
		.terms {
			margin: 15px 0 20px 0;

			a {
				color: var(--Primarycolor);
			}
		}

		.control-label {
			padding-top: 7px;
			margin-bottom: 3px;
			text-align: right;
			color: #8f8371;
			font-size: 16px;
			margin-top: 10px;
		}
	}

	.contact-map-section {
		padding: 70px 15px;
		background: #f4f1ed;

		.container {
			background: #fff;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border: 1px solid #dadada;
			padding: 0;

			.left {
				padding: 20px 20px 0 20px;

				p {
					color: #8f8371;
					font-size: 16px;
					margin: 0 0 15px 0;
				}

				ul {
					padding: 0;
					margin: 0 0 20px 0;
					list-style: none;

					li {
						color: #8f8371;
						font-size: 16px;
						margin: 0 0 10px;
						display: flex;
						position: relative;
						padding: 0 0 10px 25px;
						border-bottom: 1px dashed rgb(143 131 113 / 0.25);
						line-height: 24px;

						a {
							color: #8f8371;
						}

						.fa {
							color: #8f8371;
							position: absolute;
							left: 0;
							top: 2px;

						}

						&:last-child {
							border: none !important;
						}
					}
				}
			}

			.right {
				padding: 0;

				iframe {
					height: 400px;
				}
			}
		}
	}
}

.contact-content {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

		@media (max-width:767px) {
			padding-left: 18px;
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		a {
			background: var(--Primarycolor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: #706758;
			}
		}
	}
}


@media (max-width: 767px) {
	.static-view {
		.static-content {
			padding: 40px 0;

			h3 {
				font-size: 30px;
			}

			.unit-list-section {
				.col-md-4 {
					width: 100%;
				}
			}
		}

		.contact-map-section {
			padding: 50px 15px;
		}

		.inner-page-banner {
			height: 280px;
		}

		.banner-slide {
			img {
				height: 330px;
			}
		}
	}

}

@media all and (min-width: 640px) and (max-width: 767px) {
	.static-view {
		.static-content {
			.unit-list-section {
				.col-md-4 {
					width: 50%;
				}
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.static-view {
		.static-content {
			padding: 50px 0 40px 0;
		}
	}
}