@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.icon-img {
	max-width: 250px;
	margin: 0 auto;
}

.mb-5 {
	margin-bottom: 5rem;
}

section {
	.container {
		h2 {
			margin-bottom: 30px;
		}
	}
}

.flex-row-reverse {


	@media (min-width:993px) {
		flex-direction: row-reverse;
		display: flex;
		flex-wrap: wrap;
	}


	.cont-search-bl {
		@media (min-width:1025px) {
			padding-right: 5rem;
			/* padding-top: 5rem; */
			padding-top: 0;
		}

		p {
			font-size: 2rem;
		}
	}
}

.btn-style2 {
	background-color: #a39a8b;
	color: #fff;
	border-radius: 15px;
	font-size: 18px;
	margin-top: 30px;

	&:after {
		background-image: url(RESOURCE/img/icon-arrow.png);
		content: "";
		width: 32px;
		height: 12px;
		background-repeat: no-repeat;
		display: inline-block;
		margin-left: 8px;
		background-size: contain;
	}

	&:hover {
		&:after {
			filter: brightness(0) saturate(100%) invert(16%) sepia(1%) saturate(0%) hue-rotate(113deg) brightness(99%) contrast(90%);
		}
	}
}

.flex-row {
	@media (min-width:993px) {
		display: flex;
		flex-wrap: wrap;
	}

	.cont-search-bl {
		@media (min-width:1025px) {
			padding-left: 5rem;
			/* padding-top: 5rem; */
			padding-top: 0;
		}

		p {
			font-size: 2rem;
		}
	}
}

.flex-row-reverse,
.flex-row {
	.col-md-6 {
		.row {
			margin: 0 -7px;

			.col-xs-4,
			.col-xs-8,
			.col-xs-12 {
				padding: 0 7px;
			}
		}
	}

	.content-bl-style {
		position: relative;
		padding-left: 140px;

		&.cont-search-bl {
			padding-top: 0;
		}
	}

	.icon-heading {

		height: 80px;
		display: flex;
		align-items: center;

		.icon-bl {
			width: 100px;
			height: 100px;
			background-color: #d9d9d9;
			border-radius: 100%;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 20px;
			padding: 20px;
		}
	}
}


.img-col-bl {
	height: 150px;
	margin-bottom: 14px;

	&.height-250px {
		height: 250px;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}



.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

html {
	scroll-behavior: smooth;
}

.layout-row {
	@mixin flexlayout row;
}

.mb-2 {
	margin-bottom: .5rem !important;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

* {
	outline: 0 !important;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
}

.img-block-2 {
	width: 100%;
	height: 383px;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.overflowscrolls {
	@mixin overflowscrolls;
}

.bg-grey {
	background-color: #F2F2F2;
}

.img-responsive {
	max-width: 100%;
	width: 100%;
	object-fit: cover;
}

.heading2 {
	margin-bottom: 25px;
}

.p-80 {
	padding: 80px 0;

	@media (max-width:992px) {
		padding: 40px 0;
	}
}

.mt-80 {
	margin-top: 80px;

	@media (max-width:992px) {
		margin-top: 40px;
	}

}



.mb-30 {
	margin-bottom: 30px;
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}


@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

/* fonts icons */
.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;

	&.fa-parking-circle {
		&:before {
			content: "\f615";
		}
	}

	&.fa-fire-place {
		&:before {
			content: "\f79a";
		}
	}

	&.fa-tree-alt {
		&:before {
			content: "\f400";
		}
	}

	&.fa-dog.cross {
		border: 1.5px solid var(--black);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border-radius: 50%;
		width: 28px;
		height: 28px;

		&:before {
			font-size: 16px;
		}


		&:after {
			width: 1.5px;
			height: 100%;
			position: absolute;
			content: "";
			background-color: #4a494a;
			background-color: var(--black);
			transform: rotate(-45deg);
		}
	}

	&.fa-container-storage {
		&:before {
			content: "\f4b7";
		}
	}

	&.fa-wheelchair {
		&:before {
			content: "\f193";
		}
	}

	&.fa-washer {
		&:before {
			content: "\f898";
		}
	}

	&.fa-microwave {
		&:before {
			content: "\e01b";
		}
	}
}

.fas {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;

	&.fa-microwave {
		&:before {
			content: "\e01b" !important;
		}
	}
}

.fad {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;

	&.fa-umbrella-beach {
		&:before {
			content: "\f5ca";
		}
	}
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

	&.fa-blanket {
		&:before {
			content: "\f498";
		}
	}
}

.icon {
	width: 35px;
	height: 35px;
	display: inline-block;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background-position: center !important;

	&.icon-location {
		background: url(RESOURCE/img/icon-location.svg);
	}

	&.icon-backofen {
		background: url(RESOURCE/img/icon-backofen.svg);
	}

	&.icon-dusche {
		background: url(RESOURCE/img/icon-dusche.svg);
	}

	&.icon-gefrierfach {
		background: url(RESOURCE/img/icon-gefrierfach.svg);
	}

	&.icon-kuche {
		background: url(RESOURCE/img/icon-kuche.svg);
	}

	&.icon-kuhlschrank {
		background: url(RESOURCE/img/icon-kuhlschrank.svg);
	}

	&.icon-mikrowelle {
		background: url(RESOURCE/img/icon-mikrowelle.svg);
	}

	&.icon-spulmaschine {
		background: url(RESOURCE/img/icon-spulmaschine.svg);
	}

	&.icon-toaster {
		background: url(RESOURCE/img/icon-toaster.svg);
	}

	&.icon-trockner {
		background: url(RESOURCE/img/icon-trockner.svg);
	}

	&.icon-tv {
		background: url(RESOURCE/img/icon-tv.svg);
	}

	&.icon-waschmaschine {
		background: url(RESOURCE/img/icon-waschmaschine.svg);
	}

	&.icon-wifi {
		background: url(RESOURCE/img/icon-wifi.svg);
	}

	&.icon-balcony {
		background: url(RESOURCE/img/icon-balcony.svg);
		background-size: 48px !important;
		background-position: center;
	}
}














.wc {


	&:before {
		content: " ";
		display: block;
		background: url(RESOURCE/img/wc-icon.png);
		width: 22px;
		height: 22px;
		background-size: 22px;
		background-repeat: no-repeat;
		background-position: center;
	}
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: var(--Primarycolor);
	color: #FFF !important;
	border: 1px solid var(--Primarycolor);
	border-radius: 0;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: rgb(143 131 113 / 0.8);
	border: 1px solid rgb(143 131 113 / 0.8);
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__wrapper--full-screen {
	z-index: 99 !important;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.btn {
	border-radius: var(--btn-border-radius);

	&.btn-primary {
		background: var(--Primarycolor);
		border: 1px solid var(--Primarycolor);
	}

	&.btn-secondary {
		background: var(--Secondarycolor);
		border: 1px solid var(--Secondarycolor);
		color: #fff;
	}

}

.mb-15 {
	margin-bottom: 15px;
}


.form-control {
	border-radius: 0 !important;
}

.mt-2 {
	margin-top: 0.5rem;
}

.properties-list {
	i {
		font-style: normal !important;
	}
}

.fa-soap {
	font-weight: 300 !important;
}