@import "setup.css";

.header-main.affix {
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	box-shadow: 0 0px 10px 0px rgb(0 0 0 / 20%);
}

.header-main {
	padding: 0;
	top: 0;
	position: fixed;
	z-index: 9;
	width: 100%;
	background: rgb(255 255 255/.9);

	.container {


		.row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@media (max-width:992px) {
				display: flex;
				flex-direction: row-reverse;
			}
		}
	}

	.logo {
		img {
			max-width: 260px;

		}
	}


	.header-menu-top {

		justify-content: end;
		width: 100%;

		ul {
			padding: 0;
			margin: 15px 0 10px;
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			width: 100%;

			@media (max-width:992px) {
				margin: 0 0 10px;
			}

			li {
				display: flex;
				align-items: center;

				.plr-0 {
					padding-left: 0;
					padding-right: 0;
				}

				a {
					color: var(--Primarycolor);
					font-size: 15px;
					text-decoration: none;

					@media (max-width:992px) {
						font-size: 20px;
					}

					span {
						@media (max-width:992px) {
							display: none;
						}
					}
				}
			}
		}
	}
}



.to-top {
	position: fixed;
	cursor: pointer;
	bottom: 15px;
	display: none;
	right: 10px;
	width: 32px;
	height: 32px;
	border-radius: var(--border-radius);
	background-color: var(--Secondarycolor);
	color: #fff;
	z-index: 200;
	line-height: 32px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	&:hover {
		color: #fff;
	}

}

.vo-nav-bar {
	overflow: visible;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width:992px) {
		position: absolute;
		right: 0;
	}

	ul {
		width: 100%;
		align-items: center;
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;


		li {
			min-height: 65px;
			display: flex;
			align-items: center;
			position: relative;
			margin: 0 25px 0 0;

			a {
				&:hover {
					border-bottom: 2px solid var(--Primarycolor);
				}

			}
		}
	}

	a {
		float: left;
		display: block;
		text-align: center;
		padding: 0px;
		text-decoration: none;
		font-size: 17px;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		color: var(--Primarycolor);
		border-bottom: 2px solid transparent;
	}

	.active {
		background-color: transparent;
		color: var(--Primarycolor);
	}

	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
		height: 65px;
		display: flex;
		align-items: center;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 17px;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		border: none;
		outline: none;
		color: var(--Primarycolor);
		padding: 5px 0;
		margin: 0;
		background: transparent;
		height: auto;
		display: flex;
		-webkit-box-align: center;
		align-items: center;

		.fa {
			margin: 2px 0 0 5px;
		}
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 180px;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
		z-index: 1;
		top: 65px;
	}

	.vo-dropdown-content a {
		float: none;
		color: var(--Primarycolor);
		padding: 5px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
		font-size: 15px;
		border: none !important;
	}

	a:hover,
	.vo-dropdown:hover .dropbtn {}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
			padding: 0;
			background: var(--Primarycolor);
			display: flex;
			width: 40px;
			height: 40px;
			align-items: center;
			justify-content: center;
			margin: -40px 0 0 0;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}
}


@media (max-width: 992px) {

	.vo-nav-bar.opened {
		position: relative;
	}

	.vo-nav-bar.opened .icon {
		position: absolute;
		right: 0;
		text-align: right;
		display: flex;

	}

	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
		width: 100%;
		background: transparent;
		height: auto;
		min-height: auto;
		top: 0;
		box-shadow: none;
		padding: 5px 0 10px 0;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}

	.vo-nav-bar {

		margin: 0 0 0 0;
		padding: 5px 0;

		a {
			color: #fff;
		}

		ul {
			display: none;

			li {
				a {
					color: #fff;
				}
			}
		}

		.vo-dropdown {
			height: auto;
			width: 100%;
			display: block;

			.dropbtn {
				color: #fff;
			}
		}
	}

	.vo-nav-bar.opened {
		a {
			color: #fff;
		}

		ul {
			display: block;
			margin: 25px 0 10px 0;
			background: var(--Primarycolor);
			margin: 0 0 10px;
			padding: 10px 10px;
			position: absolute;
			top: 5px;
			z-index: 5;

			li {
				min-height: auto;
				margin: 0;

				a {
					width: 100%;
					color: #fff;
				}
			}
		}
	}

	.header-main {
		.logo {

			img {
				max-width: 100px;
			}
		}

		.header-menu-top {
			width: calc(100% - 55px);
			position: relative;
			z-index: 1;

			ul {
				justify-content: flex-end;
				margin-bottom: 0;
			}
		}
	}

	.header-main.affix {


		.header-menu-top {}

		.vo-nav-bar {}
	}
}

@media (max-width:767px) {
	.vo-nav-bar {
		right: 15px;
	}
}

@media all and (max-width: 992px) {
	.vo-nav-bar.opened {
		position: absolute;
	}



	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
		width: 100%;
		background: transparent;
		height: auto;
		min-height: auto;
		top: 0;
		box-shadow: none;
		padding: 5px 0 10px 15px;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}

	.vo-nav-bar {

		margin: 0 0 0 0;
		padding: 5px 0;

		a.icon {
			margin: 0 0 0 0;
			display: flex !important;
			float: right;
			display: block;
			padding: 0;
			background: var(--Primarycolor);
			display: flex;
			width: 40px;
			height: 40px;
			align-items: center;
			justify-content: center;
			margin: 0 0 0 0;
			position: absolute;
			top: -35px;
		}

		a {
			color: #fff;
			display: block !important;
		}

		ul {
			display: none;

			li {
				a {
					color: #fff;
				}
			}
		}

		.vo-dropdown {
			height: auto;
			width: 100%;
			display: block;

			.dropbtn {
				color: #fff;
			}
		}
	}

	.vo-nav-bar.opened {
		a {
			color: #fff;
		}

		ul {
			display: block;
			margin: 1px;
			background: var(--Primarycolor);
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;

			li {
				min-height: auto;
				margin: 0;

				a {
					width: 100%;
					color: #fff;
				}
			}
		}
	}

	.header-main {
		.container {
			display: block;
		}

		.logo {
			padding: 10px 15px;

		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.header-main {
		.logo {
			img {
				max-width: 100%;
			}
		}
	}

	.vo-nav-bar {
		a {
			font-size: 15px;
		}

		.vo-dropdown {
			.dropbtn {
				font-size: 15px;
			}
		}
	}
}

.nowrap {
	white-space: nowrap;
}