.searchpanel-v3 {

	max-width: 290px;

	.modal-content {
		border: none;
	}


	@media (min-width:768px) {
		.modal-content {
			box-shadow: none;
			border-radius: 0;

			.modal-header {
				display: none;
			}
		}
	}


	.sidebar-search {

		padding: 5px;

		@media(max-width:767px) {
			padding-bottom: 60px;
			overflow: hidden;
		}
	}

	.search-bar {
		padding: 10px 15px;


		.title {
			font-weight: 500;
		}

	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;


			.checkbox {
				padding: 0 0 0 5px;
			}
		}
	}

	.search-bar-content {
		padding: 15px;

		.label-li {
			padding-left: 20px;

			li {
				list-style: none;
			}
		}

		.filter-icon {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fa {
				position: absolute;
				top: 16px;
				right: 10px;
				font-size: 1.5rem;
				opacity: 0.5;
				pointer-events: none;

				@media (max-width:992px) {
					top: 10px;
				}
			}
		}

	}

	.sidebar-search {
		@media (min-width:768px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:768px) {
				display: none;
			}



			@media (max-width:767px) {

				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}

	#searchpanel {
		.container {
			width: auto;
		}

		@media (min-width:768px) {
			position: relative;
			display: block !important;
			overflow: visible;

			.close {
				display: none;
			}

			&.modal {
				z-index: 3 !important;
			}

			&.fade {
				opacity: 1;
			}

			.modal-dialog {
				width: auto;
				-webkit-transform: translateY(0%);
				transform: translateY(0%);
				margin: 0;
				pointer-events: inherit;
			}
		}


		@media (max-width:767px) {
			.search-bar {
				border: 1px solid #eee;
				border-top: none;
			}

			&.modal.fade {
				.modal-dialog {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
					position: fixed !important;
				}

				&.in {
					.modal-dialog {
						-webkit-transform: translateX(0);
						transform: translateX(0);
						pointer-events: inherit;
					}
				}
			}
		}

		.modal-dialog {
			@media (max-width:767px) {
				margin: 0;
				padding: 50px 15px 15px;
				z-index: 20001;
				max-width: 650px;
				width: 100%;

				overflow-y: scroll;
				height: 100vh;

				>.close {
					padding: 0 8px 1px;
					position: absolute;
					top: 6px;
					right: 16px;
					z-index: 50;
					opacity: 1;
					font-size: 36px;
				}
			}
		}
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;

		.fa {
			position: absolute;
			right: 1rem;
			bottom: 18px;

			font-size: 22px;
			display: block;
			line-height: 21px;

			@media (max-width: 992px) {
				font-size: 16px;
				bottom: 13px;
				right: 10px;
			}
		}

	}

	.btn {
		border-radius: 5px;

		&.btn-primary {
			background-color: #967740;
			border-color: #967740;

			&:hover {
				background-color: #383e42;
				border-color: #383e42;
			}
		}

		&.btn-secondary {
			background-color: #383e42;
			border-color: #383e42;

			&:hover {
				background-color: #967740;
				border-color: #967740;
			}
		}
	}

	.form-control {
		border-radius: 3px !important;

		cursor: pointer;
		text-align: left;
		padding-right: 24px !important;

		@media (min-width:1200px) {

			padding: 14px 1rem;
			height: auto;
		}

		/*	@media (max-width:1199px) {
			font-size: 13px;
			height: 55px;
		} */

		@media (max-width:992px) {

			height: auto;

		}


		&.btn-lg {

			font-size: 16px;

			@media (min-width:1200px) {
				font-size: 16px;
				padding: 14px 25px;
				height: auto;
			}

			/*	@media (max-width:1199px) {
				font-size: 13px;
				height: 55px;
			} */

			@media (max-width:992px) {
				font-size: 16px;
				height: auto;
			}

		}

	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: #fff;
	}

	.list-group-item {

		text-decoration: none;

		&:hover {

			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--Primarycolor);
			color: #555;
		}
	}

}