.blog {
	.inner-page-banner {
		width: 100%;
		height: 320px;
		background-size: cover !important;
		background-position: 50% !important;
		background: url(RESOURCE/img/sbg6.jpg);
	}

	h2 {
		margin: 0 0 30px;
		font-size: 40px;
		font-weight: 500;
		color: var(--Primarycolor);
		position: relative;
		width: 100%;

		&:after {
			width: 80px;
			height: 2px;
			display: block;
			content: " ";
			background: #8f8371;
			background: var(--Primarycolor);
			margin-top: 15px;
		}
	}

	.blog-entry {
		color: #333;
		text-decoration: none;
		display: block;
		position: relative;
		margin-bottom: 30px;
		box-shadow: 0 2px 20px 2px rgba(0, 0, 0, .1);
		padding: 30px 15px;

		.blog-header {
			margin-bottom: 10px;
		}

		.row {
			margin: 0;

			img {
				width: 100%;
				height: auto;
			}

			h3 {
				text-align: left;
				margin: 0 0 20px;
				font-size: 24px;
				font-weight: 600;
				color: #817666;
			}

			.blog-header {
				background: var(--Primarycolor);
				color: #fff;
				max-width: 80px;
				height: 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
			}

			p {
				color: #817666;
				text-align: left;
				font-size: 16px;
				line-height: 28px;
				margin: 0;
			}
		}
	}

	.entries {
		padding: 70px 0;
	}

}

.blogentry {
	.inner-page-banner {
		width: 100%;
		height: 320px;
		background-size: cover !important;
		background-position: 50% !important;
		background: url(RESOURCE/img/sbg6.jpg);
	}

	.blog-inner-content {
		padding: 70px 0 50px 0;

		h2 {
			margin: 0 0 30px;
			font-size: 40px;
			font-weight: 500;
			color: var(--Primarycolor);
			position: relative;
			width: 100%;

			&:after {
				width: 80px;
				height: 2px;
				display: block;
				content: " ";
				background: #8f8371;
				background: var(--Primarycolor);
				margin-top: 15px;
			}
		}

		h4 {
			color: var(--Primarycolor);
			margin: 0 0 20px 0;
			font-size: 20px;
			line-height: 28px;
		}

		p {
			color: var(--Primarycolor);
			margin-bottom: 20px;
			font-size: 16px;
			line-height: 24px;
		}

		.mb-20 {
			margin-bottom: 20px;
		}
	}

	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}