@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}

:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--Primarycolor: #8f8371;
	--cal_not_available: #cd7898;
	--Secondarycolor: #333;
	--color-checkbox-success: #8f8371;
}