@import "setup.css";

.indexpage {
	.blog-box {
		padding: 15px;
		position: absolute;
		z-index: 5;
		left: 50%;
		top: 280px;
		transform: translate(-50%, 0);
		width: 100%;
		max-width: 650px;
		opacity: 0.75;

		@media all and (min-width: 992px) and (max-width: 1199px) {
			top: 220px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			top: 140px;
		}

		@media all and (min-width: 641px) and (max-width: 767px) {
			top: 120px;
		}

		@media all and (min-width: 481px) and (max-width: 640px) {
			top: 120px;
		}

		@media all and (min-width: 320px) and (max-width: 480px) {
			top: 80px;
		}


		>div {
			text-align: center;
			background-color: #ebebeb;
			padding: 15px;
			border-radius: 10px;
			box-shadow: 0 2px 5px #777777;
			border: 1px solid #707070;

			@media (max-width:767px) {
				font-size: 12px;
				line-height: 1.2;

			}
		}
	}

	.home-slider-section {
		position: relative;
	}

	.owl-carousel {
		z-index: 0;
	}

	.banner-slide {

		.slide1 {
			background-image: url(RESOURCE/img/banner-slide1.jpg);
		}

		.slide2 {
			background-image: url(RESOURCE/img/banner-slide2.JPG);
		}

		.slide3 {
			background-image: url(RESOURCE/img/banner-slide3.JPG);
		}

		.slide4 {
			background-image: url(RESOURCE/img/banner-slide4.JPG);
		}

		.slide5 {
			background-image: url(RESOURCE/img/banner-slide5.JPG);
		}

		.slide6 {
			background-image: url(RESOURCE/img/banner-slide6.JPG);
		}

		.slide7 {
			background-image: url(RESOURCE/img/banner-slide7.JPG);
		}

		.slide8 {
			background-image: url(RESOURCE/img/banner-slide8.JPG);
		}

		.slide9 {
			background-image: url(RESOURCE/img/banner-slide9.JPG);
		}

		.slide10 {
			background-image: url(RESOURCE/img/banner-slide10.JPG);
		}

		.slide11 {
			background-image: url(RESOURCE/img/banner-slide11.jpg);
		}

		.slide12 {
			background-image: url(RESOURCE/img/banner-slide12.jpg);
		}

		.slide13 {
			background-image: url(RESOURCE/img/banner-slide13.JPG);
		}

		.slide14 {
			background-image: url(RESOURCE/img/banner-slide14.jpg);
		}

		.slide15 {
			background-image: url(RESOURCE/img/banner-slide15.JPG);
		}

		.slide16 {
			background-image: url(RESOURCE/img/banner-slide16.JPG);
		}

		.slide17 {
			background-image: url(RESOURCE/img/banner-slide17.JPG);
		}

		.slide18 {
			background-image: url(RESOURCE/img/banner-slide18.JPG);
		}

		.slide19 {
			background-image: url(RESOURCE/img/banner-slide19.JPG);
		}

		.slide20 {
			background-image: url(RESOURCE/img/banner-slide20.JPG);
		}

		.slide21 {
			background-image: url(RESOURCE/img/HeroSilder1Mai22.jpg);
		}

		.slide22 {
			background-image: url(RESOURCE/img/HeroSilder2Mai22.jpg);
		}

		.slide23 {
			background-image: url(RESOURCE/img/HeroSilder3Mai22.jpg);
		}

		.slide24 {
			background-image: url(RESOURCE/img/HeroSilder4Mai22.jpg);
		}

		.slide25 {
			background-image: url(RESOURCE/img/HeroSilder5Mai22.jpg);
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}


		.item {
			height: 740px;
			background-size: cover;
			background-position: center;
		}

	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {

		min-height: 500px;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}



	.searchbar {

		margin-top: 20px;
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
		max-width: 100%;
		margin: 0;
		padding: 20px 0;
		background: transparent;

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
				background: var(--Primarycolor);
				border: 1px solid var(--Primarycolor);
				box-shadow: none;
				height: 40px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 15px;
			}

			.search-popup-trigger {
				cursor: pointer;
				border: 1px solid #dfdfdf;
				height: 40px;
				font-size: 15px;
				box-shadow: none;
				padding: 10px 15px;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		/*edia (min-width:768px) {
			max-width: 650px;
		}

		@media (min-width:993px) {
			max-width: 950px;
		}*/

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}