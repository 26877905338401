@import "common.css";
@import "setup.css";
@import "searchpanel.css";
@import "checkbox.css";

.icon-img {
	max-width: 200px;
}


.image-bl {
	@media (min-width:993px) {
		max-width: 988px;
	}

	width:100%;
	display:block;
	margin:0 auto;
	margin-top: 80px;
}

.brief-p {
	font-family: 'Roboto', sans-serif;
	line-height: 28px;
	font-size: 16px;
	margin-top: 0;
	color: #555;
	margin-bottom: 20px;
}

.search-facility-bl {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.link {
		position: absolute;
		inset: 0 0 0 0;
		margin: 0 auto;
		display: block;
		z-index: 5;
	}

	.facility-title {
		padding: 5px 20px;
		background: rgba(139, 132, 117, .7);
		border-radius: 5px;
		position: absolute;
		bottom: 40px;

		@media (max-width:992px) {
			bottom: 40px;
		}

		span {
			font-size: 24px;
			line-height: 29.05px;
			font-weight: normal;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.flex-direction {
	@media (max-width:767px) {
		display: flex;
		flex-direction: column-reverse;
	}
}

.properties-bl {
	h2 {
		font-size: 24px;
		border-bottom: 1px solid #000;
		padding-bottom: 12px;
		display: inline-block;
	}
}

#accordion {

	.card {
		background-color: #8B8475;

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0;
		}

		.card-body {
			padding: 10px;
			background-color: #fff;
			border: 1px solid #8B8475;

			p {}
		}

	}

	.btn-link {
		font-weight: normal;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		white-space: inherit !important;
		text-align: left;
		font-size: var(--font-size-main);
		padding: .5rem 1rem;
		position: relative;

		@media (max-width:992px) {
			padding-right: 35px;
		}

		span {
			font-size: 20px;
			color: #fff;
		}

		&[aria-expanded="true"] {
			&:after {
				background-image: url(RESOURCE/img/icon-minus.svg);
				width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: cover;
				filter: invert(1);
			}

		}

		&:after {
			content: "";
			position: absolute;
			right: 15px;
			top: 10px;
			width: 20px;
			height: 20px;
			display: inline-block;
			background-repeat: no-repeat;
			background-size: cover;
			filter: invert(1);
			background-image: url(RESOURCE/img/icon-plus.svg);
			transition: all 0.2s ease;
			transform: rotate(0deg);
			font-family: "Font Awesome 5 Free";
			font-weight: 700;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;



		}

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}
}



.search-main {
	margin: 112px 0 0;
	border-top: 1px solid var(--Primarycolor);

	.list-view,
	.map-view {
		margin-top: 30px;
	}
}

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.more-btn-area {
	@media (min-width: 768px) {
		display: none;
	}

}

.result-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.search-main {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	@media (max-width:767px) {
		display: block;
	}

	.result-pane {
		width: 100%;
		padding: 3px;

		color: #565a5c;

		.map-show-btn {
			background: var(--Primarycolor);
			border: 1px solid var(--Primarycolor);
			color: #fff;
			display: flex;
			padding: 5px 10px;
			margin: 0 0 10px 0;
			text-decoration: none;
		}

		#region-trigger {
			width: 100%;
			height: 35px;
			margin: 2px 0;
		}

		.date-guest-triger {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			.datepicker-trigger {
				width: 49.5%;

				.btn {
					width: 100%;
					margin: 2px 0;
				}
			}

			#guest-trigger {
				width: 49.5%;
				margin: 2px 0;
			}
		}



		a {
			color: inherit;
		}

		.a-color {
			color: #817666;
		}



		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}

		.map-pane {
			.mapnote {

				@media (max-width: 767px) {
					min-height: 85px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 100px;
					font-size: 16px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					min-height: auto;
				}
			}

			.map-main {
				height: calc(100vh - 189px);
				margin-bottom: 50px;

				@media (max-width: 767px) {
					height: calc(100vh - 241px);
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					height: calc(100vh - 201px);
					font-size: 16px;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					height: calc(100vh - 189px);
				}

				&.no-map {
					@media all and (min-width: 768px) and (max-width: 991px) {
						height: calc(100vh - 101px);
					}

					.mapnote {}
				}

			}
		}
	}

	.map-pane {

		/* 		
     background-color: #f5f5f5;
     */
		.map-main {
			height: calc(100vh - 212px);

			@media all and (min-width: 768px) and (max-width: 991px) {
				height: calc(100vh - 223px);
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: calc(100vh - 260px);
			}



			.flex {
				height: 100%;

				>div {
					height: 100%;

					&:first-child {
						height: auto;
					}
				}
			}
		}

		.mapnote {
			padding: 15px;
			font-size: 16px;

			@media (max-width: 767px) {
				font-size: 12px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				min-height: 120px;
				font-size: 12px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				min-height: 145px;
			}

			a {
				color: var(--Primarycolor);
			}
		}

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}

		.property-bottom {
			display: flex;
			align-items: center;
			justify-content: center;

			.properties-list {
				border: 1px solid #000;
				width: 27px;
				height: 27px;
				font-size: 14pt;
				line-height: 24px;
				margin-right: 3px;
				margin-top: 5px;
				border-radius: 6px;
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
			}

			.properties-list.crossed {
				.fa-dog {
					position: relative;

					&:after {
						width: 1.5px;
						height: 20px;
						background: #565a5c;
						display: block;
						content: " ";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: 0 auto;
						transform: rotateZ(140deg);
						z-index: 1;
					}
				}
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f4f4f4;
	}

	.more-btn-panel {
		padding-top: 30px;
		padding-bottom: 20px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.pagination>li>a {
		color: #333;
	}

	.pagination>.active>a {
		color: #fff;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;
			padding: 5px;
			padding-bottom: 15px;



			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}

			.owl-carousel {
				overflow: hidden;
				height: 100%;

				.owl-stage-outer {
					height: 100%;

					.owl-stage {
						height: 100%;

						.owl-item {
							height: 100%;

							.item {
								height: 100%;
							}
						}
					}
				}
			}

			.owl-nav {
				position: absolute;
				top: 42%;
				left: 0;
				width: 100%;
				z-index: 99;

				.owl-prev {
					width: 40px;
					height: 40px;
					position: absolute;
					left: 0;
					outline: none;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;

					span {
						font-size: 50px;
						color: #fff;
					}
				}

				.owl-next {
					width: 40px;
					height: 40px;
					position: absolute;
					right: 0;
					outline: none;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;

					span {
						font-size: 50px;
						color: #fff;
					}
				}
			}
		}


	}


	.search-result-properties {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 5px 0 10px 0;

		>div {
			float: left;
			text-align: center;
			padding: 3px;

			border-right: 1px solid #ccc;

			:last-child {
				font-weight: bold;
			}
		}

		>div:last-child {
			border: none;
		}

	}



	.search-bar {
		/*padding: 5px 10px;*/
		background-color: #f5f2f0;
		padding: 10px 15px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	.list-view {
		.map-pane {
			display: none;
		}
	}

	@media (max-width:767px) {


		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}


	}

	.results {
		.srow {
			@mixin flexlayout row;

			.info-col {
				width: 60%;
			}

			.img-col {
				@mixin flex;
				position: relative;

				.img-content {
					@media (min-width:768px) {
						position: absolute;
						@mixin size100;
					}

					@media (max-width:767px) {
						position: relative;
					}


					.full-img {
						height: 100%;
						object-fit: cover;

					}
				}

			}

		}
	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			width: 60%;
			max-width: 60%;

			.more-btn-panel {
				display: none;
			}


		}



	}
}

.search-main {
	.result-pane {
		background: #f4f4f4;
	}

	.results {
		padding: 5px;
		margin-bottom: 40px;

		.srow {
			border: 1px solid #C3C0BD;
			border-top: 5px solid #C3C0BD;
			padding: 0;
			margin: 30px 0 0;



			.info-col {
				padding: 10px 15px;

				a {
					text-decoration: none;
				}

				h4 {
					font-size: 24px;
					font-weight: 400;
					color: #817666;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin: 0;
					white-space: normal;
					justify-content: space-between;

					a {
						text-decoration: none;
						color: #817666;
						width: 70%;
						margin: 0 0 10px 0;
					}



					.fav-icon {
						width: auto;
						text-align: right;
						background: no-repeat;
						background-color: #b5aca0;
						border: 1px solid #b5aca0;
						color: #fff;
						padding: 5px;
						margin: 0 0 10px;
						text-decoration: none;
						font-size: 15px;
						outline: none;

						&:hover {

							background: var(--Primarycolor);
							border: 1px solid var(--Primarycolor);
						}
					}
				}

				h6.text-truncate {
					margin: 0 0 15px 0;
					color: #817666;
					font-size: 14px;
				}

				.search-result-properties {
					background-color: #f4f1ed !important;
					width: 100%;
					margin: 0;
					padding: 15px 15px;

					.properties-list {
						padding: 3px 0;
						width: 100%;
						display: flex;
						align-items: center;
						border: none;
						color: #817666;
						font-size: 14px;

						&:before {
							display: block;
							content: "\f00c";
							font: normal normal normal 12px/1 FontAwesome;
							margin: 0 7px 0 0;
						}

						.fa {
							margin: 0 0 0 5px;
						}

					}
				}

				.properties-list.crossed {
					.fa-dog {
						position: relative;

						&:after {
							width: 1.5px;
							height: 20px;
							background: #565a5c;
							display: block;
							content: " ";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin: 0 auto;
							transform: rotateZ(140deg);
							z-index: 1;
						}
					}
				}

				.property-bottom {
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					margin: 10px 0 10px 0;

					.properties-list {
						border: 1px solid #000;
						width: 27px;
						height: 27px;
						font-size: 14pt;
						line-height: 24px;
						margin-right: 3px;
						margin-bottom: 5px;
						border-radius: 6px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.properties-list.crossed {
						.fa-dog {
							position: relative;

							&:after {
								width: 1.5px;
								height: 20px;
								background: #565a5c;
								display: block;
								content: " ";
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								margin: 0 auto;
								transform: rotateZ(140deg);
								z-index: 1;
							}
						}
					}
				}
			}

			.detail-button-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 0 0 0;

				.detail-btn {
					background-color: #B5ACA0;
					border: 1px solid #B5ACA0;
					color: #fff;
					display: flex;
					padding: 5px 12px;
					margin: 0;
					text-decoration: none;
					font-size: 15px;

					&:hover {
						background: var(--Primarycolor);
						border: 1px solid var(--Primarycolor);
					}
				}
			}
		}
	}
}


.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background: var(--Primarycolor);
	color: #fff;
}

.pagination>li>a,
.pagination>li>span {
	border: none;
}

@media (max-width: 767px) {
	.search-main {
		margin: 155px 0 0;
	}

	.search-main {
		.results {
			.srow {
				display: block;

				.info-col {
					width: 100%;

					h4 {
						a {
							width: 100%;
						}
					}
				}
			}
		}

		.result-pane {
			.date-guest-triger {
				.datepicker-trigger {
					width: 100%;
				}

				#guest-trigger {
					width: 100%;
				}
			}
		}

		.results {
			.srow {
				.info-col {
					a {
						h4 {
							white-space: normal;
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}


@media all and (min-width: 768px) and (max-width: 991px) {
	.search-main {
		margin: 100px 0 0 0;
	}

	.search-main {
		.map-unit-preview {
			.preview-img {
				min-height: auto;

				img {
					max-width: 100%;
					height: auto;
				}
			}

		}

		.map-pane {
			.property-bottom {
				flex-wrap: wrap;
			}
		}
	}
}

.offer-bl-srch {
	margin-top: 14px;
	background-color: #f4f1ed;
	color: white;

	.unit-link {
		width: 100%;
		height: 100%;
	}

	.offer-box {
		position: relative;
		padding: 10px 20px;
		display: inline-flex;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center;

		.offer-text {
			background: cadetblue;
			padding: 10px;
			text-transform: uppercase;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-weight: bold;
		}

		ul {
			margin: 0;
			padding: 0;
			color: #817666;

			li {
				font-size: 16px;
				font-weight: bold;
				list-style: none;
				padding-left: 15px;
			}
		}
	}
}