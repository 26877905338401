.booking-main {
	margin: 112px 0 0 0;
	border-top: 1px solid var(--Primarycolor);
	padding: 10px 0 0 0;

	@media (max-width:992px) {
		margin: 55px 0 0 0 !important;
	}
}



.booking-view {


	.page-header {
		margin: 0 0 30px 0;

		h1 {
			margin: 0 0 10px 0;
		}
	}

	.cart-details {
		.price-table {
			td {
				.btn {
					display: none;
				}
			}
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.price-details-btn {
			color: var(--Primarycolor);
		}
	}

	.pd-form {
		label {
			font-weight: normal;
		}

		label.lrequired {}

		.control-label {
			text-align: left;
			font-weight: 400;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			.checkbox {
				a {
					color: var(--Primarycolor);
				}
			}

			label {
				font-weight: 400;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
		border-radius: 0;
		border: 1px solid var(--Primarycolor);
		color: var(--Primarycolor);
		font-size: 16px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}
}

@media (max-width: 767px) {
	.booking-main {
		margin: 135px 0 0;
	}

	.booking-view {
		.flow-btn {
			width: 100%;
			margin: 10px 0;
		}
	}
}

@media all and (min-width: 320px) and (max-width: 480px) {
	.booking-view {
		.flow-btn {
			width: 100%;
			margin: 10px 0;
		}
	}
}