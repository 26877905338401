.indexpage {
	.brief {
		padding-top: 0;
		padding-bottom: 0;
		font-family: 'Open Sans', Helvetica Neue, Sans-serif;
		line-height: 28px;
		font-size: 16px;

		.welcome-text {
			background: #f4f1ed;
			padding: 70px 0;

			h1 {
				margin: 0px 0px 20px;
				text-align: center;
				font-size: 40px;
				font-weight: 600;
				color: #817666;

				@media (max-width: 767px) {
					font-size: 32px;
				}
			}

			h2 {
				text-align: center;
				margin: 0px 0px 30px;
				font-size: 30px;
				font-weight: 600;
				color: rgb(129, 118, 102);

				@media (max-width: 767px) {
					font-size: 24px;
				}

			}

			.wir-freuen {
				text-align: center;
				margin: 0 0 30px;
				font-size: 30px;
				font-weight: 600;
				color: #817666;

				@media (max-width: 767px) {
					font-size: 24px;
				}
			}

			h3 {
				margin: 0 0 20px;
				text-align: center;
				font-size: 44px;
				font-weight: 600;
				color: #817666;
			}

			h4 {
				text-align: center;
				margin: 0 0 30px 0;
				font-size: 30px;
				font-weight: 600;
				color: #817666;
			}

			p {
				color: #817666;
				text-align: center;
			}
		}

		.content-section {
			margin-top: 0;
		}

		h2 {
			margin-bottom: 25px;
			margin-top: 10px;

			&:after {
				width: 80px;
				height: 2px;
				display: block;
				content: " ";
				background: var(--Primarycolor);
				margin-top: 15px;
				display: none;
			}

			a {
				color: #333;
				text-decoration: none;
			}
		}

		p {
			margin-top: 0;
			color: #555;
			margin-bottom: 20px;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;


		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			margin-top: 0;

			img {
				height: 380px;
				width: 100%;
				object-fit: cover;

				@media (max-width: 550px) {
					height: 250px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					height: 250px;
				}

			}

			.owl-prev {
				span {
					position: absolute;
					top: 38%;
					left: 15px;
					color: #fff;
					font-size: 65px;
					background: transparent;

					@media (max-width: 991px) {
						top: 34%;
						font-size: 50px;
					}
				}
			}

			.owl-next {
				span {
					position: absolute;
					top: 38%;
					right: 15px;
					color: #fff;
					font-size: 65px;
					background: transparent;

					@media (max-width: 991px) {
						top: 34%;
						font-size: 50px;
					}
				}
			}

		}

		.brief-image-left img {
			float: left;
			width: 100%;
		}


	}

	.wohnen-still {
		background: #f4f1ed;
		padding: 70px 0;

		h2 {
			margin: 0 0 20px;
			text-align: center;
			font-size: 44px;
			font-weight: 600;
			color: #817666;
		}

		p {
			color: #817666;
			text-align: center;
			line-height: 28px;
			font-size: 16px;
			margin: 0;
		}
	}

	.grey-bg {
		border-top: 1px solid #f4f1ed;
		border-bottom: 1px solid #f4f1ed;
		background: #f4f1ed;
		padding: 80px 0;
	}

	.white-bg {
		padding: 80px 0;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
		color: var(--Primarycolor);
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

	.three-boxes {
		padding: 80px 0 80px;
		background: #f4f1ed;

		.boxes2 {
			.box-area {
				flex-direction: row-reverse;
				border-top: 0;
				border-bottom: 0;
			}
		}

		.boxes {
			width: 100%;

			.box-area {
				background: #fff;
				margin: 0 0 0;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				border: 1px solid #dadada;

				.img {
					width: 50%;

					img {
						max-width: 100%;
						height: 350px;
						object-fit: cover;
						width: 100%;
					}
				}

				.text {
					padding: 0 15px;
					width: 50%;
					min-height: auto;

					h3 {
						color: var(--Primarycolor);
						margin: 25px 0 15px 0;
						text-align: center;
						width: 100%;
						font-size: 22px;
					}

					p {
						margin-top: 0;
						color: var(--Primarycolor);
						margin-bottom: 20px;
						text-align: center;
						font-size: 16px;
						font-weight: 300;
						width: 100%;

						span.underline {
							width: 100%;
							text-align: center;
							color: var(--Primarycolor);
							text-decoration: underline;
							font-weight: 500;
							text-align: center;
						}
					}
				}
			}
		}
	}

}

@media (max-width: 767px) {
	.indexpage {
		.brief {
			h2 {
				font-size: 24px;

				&:after {
					margin: 15px auto 0;
				}
			}

			.content-section,
			.wow {
				width: 100%;

			}

			.brief-image-left {
				width: 100%;
				margin: 0 0 20px 0;

				img {
					width: 100%;
				}
			}
		}

		.three-boxes {
			padding: 50px 0;

			.boxes {
				padding: 0;

				.box-area {
					.img {
						width: 100%;

						img {
							height: auto;
						}
					}

					.text {
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 480px) {
	.indexpage {
		.banner-slide {
			.item {
				height: 250px !important;
			}
		}

		.brief {
			p {
				font-size: 14px;
				line-height: 22px;
			}

			.welcome-text {
				padding: 60px 0 30px 0;

				p {
					font-size: 14px;
					line-height: 22px;
				}

				h3 {
					font-size: 26px;
				}

				h4 {
					font-size: 20px;
				}
			}

			.brief-image-left {
				margin: 0 0 20px 0;

				img {
					width: 100%;
				}
			}
		}

		.white-bg {
			padding: 50px 0 30px 0;
		}

		.grey-bg {
			padding: 50px 0 30px 0;
		}

		.wohnen-still {
			padding: 50px 0 50px 0;

			h2 {
				font-size: 26px;
			}

			p {
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	.white-bg {
		padding: 50px 0;
	}
}

@media all and (min-width: 481px) and (max-width: 640px) {
	.indexpage {
		.banner-slide {
			.item {
				height: 350px !important;
			}
		}

		.brief {
			.welcome-text {
				h3 {
					font-size: 40px;
				}
			}
		}

		.wohnen-still {
			h2 {
				font-size: 40px;
			}
		}
	}
}

@media all and (min-width: 641px) and (max-width: 767px) {
	.indexpage {
		.banner-slide {
			.item {
				height: 400px !important;
			}
		}

		.brief {
			.welcome-text {
				h3 {
					font-size: 40px;
				}
			}
		}

		.wohnen-still {
			h2 {
				font-size: 40px;
			}
		}
	}
}

@media all and (min-width: 768px) and (max-width: 991px) {
	.indexpage {

		.text-left,
		.indexpage .text-right {
			text-align: left;
		}

		.banner-slide {
			.item {
				height: 430px;
			}
		}
	}
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.indexpage {
		.banner-slide {
			.item {
				height: 540px;
			}
		}
	}
}