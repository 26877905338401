.main-footer {

	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}

	.footer-top {
		background: #8f8371;
		color: #fff;
		padding: 30px 0 20px 0;

		h3 {
			margin: 0 0 20px 0;
			font-size: 22px;
		}

		.contact {
			.fa {
				padding: 10px 15px 0px 0px;
			}
		}

		p {
			font-size: 15px;
			font-weight: 300;

			a {
				color: #fff;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				font-size: 15px;
				font-weight: 300;
				margin: 0 0 7px 0;
				position: relative;
				padding: 0 0 0 15px;

				a {
					color: #fff;
					text-decoration: none;
					word-break: break-all;
				}

				.fa {
					position: absolute;
					left: 0;
					top: 1px;
				}
			}
		}

		.footer3 {
			@media all and (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 30px;
			}
		}

		.footer4 {
			h3 {
				a {
					@media (max-width: 767px) {
						text-align: left !important;
					}
				}
			}

			.link {
				max-width: 350px;
				width: 100%;

				@media (max-width: 767px) {
					text-align: left !important;
				}
			}

			a {
				color: #fff;
				text-decoration: none !important;
				text-align: center;
				display: block;

				i {
					font-size: 50px;
					color: #fff !important;
				}

				span {
					display: block;
					width: 100%;
					font-size: 16px;
				}
			}
		}

	}

	.footer-bottom {
		background: #706758 !important;
		padding: 15px 0 10px 0;



		.left {
			p {
				color: #fff;
				margin: 0 0 0 0;
				font-size: 14px;
			}

			ul {
				padding: 0;
				list-style: none;
				margin: 0;
				display: flex;
				flex-wrap: wrap;

				li {
					margin: 0 10px 0 0;
					font-size: 28px !important;
					padding: 2px;
					width: 45px;
					text-align: center;
					border-radius: 5px;
					color: #dfdbd5;
					background: #8F836F;
					display: flex;
					align-items: center;
					justify-content: center;
					text-decoration: none;

					a {

						color: #dfdbd5;
						display: flex;
						align-items: center;
						justify-content: center;
						text-decoration: none;
					}
				}
			}
		}

		.right {
			ul {
				padding: 0;
				list-style: none;
				margin: 0;
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				justify-content: flex-end;

				li {
					position: relative;
					padding: 0 15px 0 0;
					margin: 0 15px 0 0;

					a {
						font-size: 16px;
						color: #fff;
						text-decoration: none;
						font-weight: 300;
					}

					&:after {
						display: block;
						content: " | ";
						position: absolute;
						right: 0;
						top: 0;
						color: #fff;
					}

					&:last-child {
						margin: 0;
						padding: 0;

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}

	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}


		.footer-link {
			color: #888;
		}


	}
}


@media (min-width: 1200px) {
	.main-footer {
		.footer-top {
			.footer1 {
				/*width: 26%;*/
			}

			.footer2 {
				/*width: 30%;*/
			}

			.footer3,
			.footer4 {
				/*width: 22%;*/
			}
		}
	}
}

@media (max-width: 767px) {
	.main-footer {

		.footer1,
		.footer2,
		.footer3 {
			margin: 0 0 20px 0;
		}

		.footer-bottom {
			p {
				text-align: center;
			}

			.left {
				ul {
					justify-content: center;
					margin: 0 0 20px 0;
				}
			}

			.right {
				ul {
					justify-content: center;
				}
			}
		}
	}
}